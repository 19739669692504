<template>
  <section class="dnc-accept">
    <div class="dnc-accept__wrapper dnc-wrapper">
      <div class="dnc-accept__img">
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512 353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336 616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512 670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z"/>
        </svg>
      </div>
      <h1 class="dnc-personal-info__header dnc-accept__header">Ошибка</h1>
      <div class="dnc-personal-info__description">
        <p>Не удалось провести платёж. <br /> Обратитесь к администратору.</p>
      </div>
      <div class="dnc-personal-info__btn">
        <NuxtLink class="dnc-button dnc-button-secondary" :to="{name: 'pay-form'}">Назад</NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup>
useHead({
  title: 'Ошибка оплаты'
})
</script>

<style scoped>
.dnc-accept {
  width: 100%;
  max-width: 340px;
}

.dnc-accept,
.dnc-accept .dnc-personal-info__header {
  text-align: center;
}

.dnc-accept__header {
  text-align: left;
}
svg {
  display: block;
  width: 150px;
  margin: 0 auto;
}
svg path {
  fill: var(--color-error);
}
</style>
